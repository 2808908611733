<template>
	<!-- 底部 -->
    <div class="index_footer">
		<div class="foot_con fontsize16">
			<div class="w1200 hxinfo">
				<div class="hlinfo_box">
					<div class="centerbox2 fontsize16">
						<div class="imgbox"><img class="imgicon" src="../../assets/logo/logo.png"/></div>
						<div class="datalist">
							<div class="textname fontsize20">找到我们</div>
							<div class="combox1">
								<!-- <div class="itemobj">
									<el-popover
									  placement="top"
									  trigger="hover">
									  <div style="margin: 0 auto; width: 168px;height: 168px;"><img src="../../assets/static/tm.png" alt="天猫" class="img100"></div>
									 <div slot="reference" class="pointer item1">
									   <img class="img" src="../../assets/static/tm.png"/>
									   <div class="text fontsize14">天猫</div>
									 </div>
									</el-popover>
								</div>
								<div class="itemobj">
									<el-popover
									  placement="top"
									  trigger="hover">
									    <div style="margin: 0 auto; width: 168px;height: 168px;"><img src="../../assets/static/dy.png" alt="抖音" class="img100"></div>
									    <div slot="reference" class="pointer item1">
										  <img class="img" src="../../assets/static/dy.png"/>
										  <div class="text fontsize14">抖音</div>
									    </div>
									</el-popover>
								</div>
								<div class="itemobj">
									<el-popover
									  placement="top"
									  trigger="hover">
									  <div style="margin: 0 auto; width: 168px;height: 168px;"><img src="../../assets/static/xcx.png" alt="小程序" class="img100"></div>
									  <div slot="reference" class="pointer item1">
									    <img class="img" src="../../assets/static/xcx.png"/>
									    <div class="text fontsize14">微信小程序</div>
									  </div>
									</el-popover>
								</div>
								<div class="itemobj">
									<el-popover
									  placement="top"
									  trigger="hover">
									  <div style="margin: 0 auto; width: 168px;height: 168px;"><img src="../../assets/static/wx.png" alt="微信" class="img100"></div>
									  <div slot="reference" class="pointer item1">
									    <img class="img" src="../../assets/static/wx.png"/>
									    <div class="text fontsize14">微信公众号</div>
									  </div>
									</el-popover>
								</div> -->
								<div class="itemobj" v-for="(item,index) in bannerList" :key="index">
									<el-popover
									  placement="top"
									  trigger="hover">
									  <div style="margin: 0 auto; width: 168px;height: 168px;"><img :src="item.videoUrl" :alt="item.title" class="img100"></div>
									  <div slot="reference" class="pointer item1">
									    <img class="img" :src="item.imageUrl"/>
									    <div class="text fontsize14">{{item.title}}</div>
									  </div>
									</el-popover>
								</div>
							</div>
						</div>
					</div>
					<div class="info_two">
						<a href="http://beian.miit.gov.cn/" target="_blank"> © 2024 苏州焦质科技有限公司.版权所有.苏ICP备2024124384号</a>
						<!-- <div class="text">地址： 江苏省苏州市常熟经济技术开发区苏州路40号</div> -->
					</div>
				</div>
			</div>
		</div>
		<!-- 查看大图 -->
		<el-dialog :visible.sync="dialogimgVisible" center title="在线客服">
		    <div style="margin: 0 auto; width: 268px;height: 268px;">
				<img src="../../assets/logo.png" alt="在线客服" class="img100">
			</div>
		</el-dialog>
    </div>
</template>
<script>
export default {
	name: 'Footer',
	data() {
		return {
			current: 0, // 当前索引
			dateyear: '',
			dialogimgVisible: false, // 控制大图
			bannerList:[],
		};
	},
	created() {
		this.dateyear = new Date().getFullYear();
		//获取轮播图
		this.getindexLogo()
	},
	methods: {
		//获取轮播图
		getindexLogo() {
			var _this = this
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 18
			this.$http.post('content/ct-wheel-advert/indexHeader/indexLogo', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					if(records.length>0){
						_this.bannerList = records
					}
					
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		See(e) {
			// window.location.href = e;
			window.open(e,'_blank');
		},
		menuClick(url) {
			console.log('我点击了menu');
			if(url){
				if(url=="mine"){
					this.$router.push({
						name: "mine",
						params:{
						    type:"order"
						}
					});
				}else if(url=="kefu"){
					//客服
					this.dialogimgVisible = true
				}else{
					this.$router.push({
						name: url
					});
				}
			}else{
				this.$message.info("此模块暂未开放")
			}
		},
	}
};
</script>
<style lang="scss" scoped>
.index_footer{
	width: 100%;
	background: #EBEEF1;
	.foot_con{
		width: 100%;
		.hlinfo_box{
			padding: 10px 0;
			.centerbox2{
				width: 100%;
				padding: 59px 0;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #D6D6D6;
				.imgbox{
					.imgicon{
						width: 159px;
						height: 70px;
					}
				}
				.datalist{
					margin-left: auto;
					height: 50px;
					.textname{
						color: #000;
						font-weight: bold;
					}
					.combox1{
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						margin-top: 14px;
					}
					.itemobj{
						margin-right: 16px;
						cursor: pointer;
						margin-bottom: 8px;
						.item1{
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 6px 12px;
							border-radius: 30px;
							background: #FFF;
							.img{
								width: 24px;
								height: 24px;
							}
							.text{
								color: #231815;
								margin-left: 5px;
							}
						}
					}
				}
			}
			.info_two{
				padding: 15px 0;
				display: flex;
				align-items: center;
				color: #666;
				a{
					color: #666;
				}
				.text{
					margin-left: 68px;
				}
			}
		}

	}
}
</style>
