import Vue from 'vue'
import store from '../store/index.js'
import {
    Message,
    MessageBox
} from 'element-ui'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
	//{
	// path: '/',
	// name: 'index',
	// redirect: '/home',
	// component: () => import('@/views/index.vue'),
	// children: [
		
	// ]}
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/home.vue'),
		meta: {
		    keepAlive: true ,// 需要缓存
			ifwhite: true // 白色菜单
		}
	},
	{
		path: '/seriesList',
		name: 'seriesList',
		component: () => import('@/views/seriesList.vue'),
		meta: {
		    keepAlive: true ,// 需要缓存
			ifwhite: true // 白色菜单
		}
	},
	{
		path: '/seriesDetail',
		name: 'seriesDetail',
		component: () => import('@/views/seriesDetail.vue'),
		meta: {
			ifwhite: true // 白色菜单
		}
	},
	{
		path: '/404',
		name: '404',
		component: () => import('@/views/404.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login.vue')
	},
]
const router = new VueRouter({
	mode: 'history',
	base: "",
	routes
})

export default router
var LoginList = ["/","/home","/login","/404"]
var whiteList = ["/","/seriesList"]
router.beforeEach(async (to, from, next) => {
	// document.title = getPageTitle(to.meta.title)
	//console.log(to)
	if(whiteList.indexOf(to.path)>-1){
		//console.log("SET_CURPAGE")
		store.commit('SET_CURPAGE', to.name)
	}
	if(to.matched.length>0){
		next()
		// if(LoginList.indexOf(to.path)<0){
		// 	if(localStorage.getItem("token")){
		// 		next()
		// 	}else{
		// 		if(to.path=="" || to.path=="/" || to.path=="/404"){
		// 			next(`/`)
		// 		}else{
		// 			//需要登录才能访问的页面
		// 			// next(`/login?redirect=${to.path}`)
		// 			Message.error("请先登录账号！")
		// 			store.commit('SET_CURPAGE', "home")
		// 			next(`/`)
		// 		}
		// 	}
		// }else{
		// 	next()
		// }
	}else{
		next('/') // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
	}
})
// 切换页面滚动回顶部
router.afterEach((to,from,next) => {
	// console.log("11322")
    window.scrollTo(0,0);
    // 或
    // window.scroll(0, 0);
});
// 处理高版本连续跳转的错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
