<template>
	<!-- 菜单 -->
	<div>
		<header id="header" class="header" @mouseenter="mouseEnterBar()" @mouseleave="mouseLeaveBar()">
			<div class="my-container" :style="newisstyle">
				<div class="w1200 flexrow flexbetween">
					<div class="logo">
						<img v-if="newifwhite" class="img100" src="../../assets/logo/Group3465735.png">
						<img v-if="!newifwhite" class="img100" src="../../assets/logo/Group13465735.png">
					</div>
					<div class="navbar navbar-expand-lg navbar-light">
						<!-- 导航栏中的矢量图：实例中product，右键查看源代码，复制a标签的内容，粘贴如下 -->
						<!-- <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"><span class="navbar-toggler-icon"></span></button> -->
						<!-- ml-auto目的是使ul位置在右边 -->
						<div class="home_tab collapse navbar-collapse" id="collapsibleNavbar">
							<ul class="navbar-nav">
								<li class="nav-item" :class="curpage == item.url ? 'cur_tab' : ''" v-for="(item, index) in menulist" :key="item.index">
									<a @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)" v-if="!newifwhite" @click="menuClick(index, item.url)" class="fontsize18 nav-link">{{ item.menu }}</a>
									<a @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)" v-if="newifwhite" @click="menuClick(index, item.url)" class="ifwhite fontsize18 nav-link">{{ item.menu }}</a>
									<!-- <a @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)" v-if="item.shxCatalogue.length>0" @click="menuClick(index, item.url)" class="fontsize18 dropdown_a nav-link">{{ item.menu }}</a> -->
									<ul @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)" v-if="item.shxCatalogue.length>0" :class="isActive==index ? 'sub-menu sub-menuact' : 'sub-menu'">
									   <li v-for="(sitem, sindex) in item.shxCatalogue" :key="sindex" @click="menuSecHandle(index, sitem.redirectUrl,sitem.index)" :class="cur_cli == sitem.id? 'cur_cli' : ''">
									      <a>{{sitem.title}}</a>
									   </li>
									</ul>
									<!-- <div v-if="index === 1" class="zhebzhao" @mouseenter="mouseEnter" @mouseleave="mouseLeave"></div> -->
								</li>
							</ul>
						</div>
					</div>
					<div class="sel_fenlei_box" @click="handlePhoneMenu">
						<span v-if="!showMenu" class="iconfont icon-fenlei icon" :class="{'text_black':!newifwhite}"></span>
						<span v-if="showMenu" class="iconfont icon-cuowu2 icon" :class="{'text_black':!newifwhite}"></span>
					</div>
				</div>
			</div>
			<div class="menuBar_phone_box" :class="{'menuBar_phone_box_show':showMenu}">
				<div class="menuBar_list">
					<div class="itemobj" v-for="(item, index) in menulist" :key="item.index">
						<div class="textbox">
							<div class="text1" style="width: 50%;" @click="menuClick(index, item.url)">{{item.menu}}</div>
							<div class="text2" style="flex: 1;height: 100%;" @click="handleshowphoneMenu"></div>
							<div class="icon iconfont icon-xiangxia2" @click="handleshowphoneMenu" v-if="item.shxCatalogue&&item.shxCatalogue.length>0"></div>
						</div>
						<div class="secmenuBar_box" :class="{'slide-out-top':showphoneMenu}" v-if="item.shxCatalogue&&item.shxCatalogue.length>0">
							<div class="secitemobj" v-for="(sitem, sindex) in item.shxCatalogue" :key="sindex">
								<div class="text1" @click="menuSecHandle(index, sitem.redirectUrl,sitem.index)">{{sitem.title}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<!-- 查看大图 -->
		<el-dialog :visible.sync="dialogimgVisible" center title="在线客服">
		    <div style="margin: 0 auto; width: 268px;height: 268px;">
				<img src="../../assets/logo.png" alt="在线客服" class="img100">
			</div>
		</el-dialog>
	</div>
</template>
<script>
import {mapState,mapMutations} from 'vuex'
export default {
	name: 'menuBar',
	props: {
		ifwhite: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
		isstyle: {
			type: String,
			default: () => {
				return "";
			}
		},
	},
	data() {
		return {
			current_index: 0, // 当前索引
			isActive: -1, //鼠标移入索引
			cur_cli: 0, //0表示没有,选择的id
			menulist: [
				{
					index: 0,
					menu: '首页',
					url: 'home',
					shxCatalogue:[
						// {
						// 	index:0,
						// 	title: '品牌简介',
						// 	redirectUrl: 'ani_0',
						// },
						// {
						// 	index:1,
						// 	title: '品牌主张',
						// 	redirectUrl: 'ani_1',
						// },
						// {
						// 	index:2,
						// 	title: '品牌故事',
						// 	redirectUrl: 'ani_2',
						// }
					],
				},
				{
					index: 1,
					menu: '科技纤维系列',
					url: 'seriesList',
					shxCatalogue:[
						// {
						// 	title: '日晒科技系列',
						// 	redirectUrl: 'seriesDetail?id=1',
						// },
						// {
						// 	title: '清凉科技系列',
						// 	redirectUrl: 'seriesDetail?id=2',
						// }
					],
				},
			],
			bgnum:1,//透明度
			fontsize:"中",
			showmenuBlack:false,
			newisstyle:"",
			newifwhite:"",
			istype:"add",//注册还是找回密码
			zhuceshow:false,//注册
			getpasswordshow:false,//找回密码
			dialogimgVisible: false, // 控制大图
			showMenu:false,//展示
			showphoneMenu:true,//展示
			ifphone:false,//是不是手机
		};
	},
	watch: {
		isstyle: {
			immediate:true,
		    handler(newVal, objVal) {
				this.newisstyle = newVal
				this.handleScroll()
		    },
		},
		ifwhite: {
			immediate:true,
		    handler(newVal, objVal) {
				this.newifwhite = newVal
				this.handleScroll()
		    },
		},
	},
	computed: {
		...mapState(['userInfo', 'isLogin','curpage','sysRole']),
	},
	created() {
		var _this = this
		this.getindexmenu()
		this.getseriesmenu()
		// 此处true需要加上，不加滚动事件可能绑定不成功
		let width = document.documentElement.clientWidth
		if (width < 1200) {
			this.ifphone = true
			// this.$nextTick(() => {
			// 	_this.showmenuBlack = true
			// 	_this.newifwhite = false
			// 	_this.newisstyle = "background: #FFF;"
			// 	setTimeout(function(){
			// 		_this.showmenuBlack = true
			// 		_this.newifwhite = false
			// 		_this.newisstyle = "background: #FFF;"
			// 	},500)
			// });
		}
		window.addEventListener("scroll", this.handleScroll, true);
		
	},
	methods: {
		...mapMutations(["SET_USERINFO",'SET_ISLOGIN']),
		//手机端弹出菜单
		handlePhoneMenu(){
			this.showMenu = !this.showMenu
		},
		//展开
		handleshowphoneMenu(){
			this.showphoneMenu = !this.showphoneMenu
		},
		//获取
		getindexmenu() {
			var _this = this
			var params = {}
			this.$http.post('content/ct-article-info/IndexInfoTitle', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data
					var list = []
					if(records.length>0){
						records.forEach((item,index)=>{
							list.push(
								{
									index:index,
									id:item.id,
									title: item.subtitle,
									redirectUrl: 'ani_'+index,
								}
							)
						})
						_this.menulist[0].shxCatalogue = list
					}
					
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		getseriesmenu() {
			var _this = this
			var params = {}
			this.$http.post('content/ct-series-info/seriesTitle', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data
					var list = []
					if(records.length>0){
						records.forEach((item,index)=>{
							list.push(
								{
									index:index,
									id:item.id,
									title: item.title,
									redirectUrl: 'seriesDetail?uid='+item.id,
								}
							)
						})
						_this.menulist[1].shxCatalogue = list
					}
					
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//鼠标进入
		mouseEnterBar(){
			if(this.ifphone){
				return;
			}
			this.showmenuBlack = true
			this.newifwhite = false
			this.newisstyle = "background: #FFF;"
		},
		//鼠标离开
		mouseLeaveBar(){
			if(this.ifphone){
				return;
			}
			var scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
			if(scrolltop < 500){
				this.showmenuBlack = false
				if(this.ifwhite){
					this.newifwhite = true
					this.newisstyle = ""
				}
			}
		},
		//查看
		handleCommand(type){
			console.log(type)
			if(type=="outlogin"){
				//退出
				this.outLogin()
			}else{
				this.$router.push({
					name:"mine",
				});
			}
		},
		//去登陆
		handlelogin(){
			this.$router.push({
				path: "/login"
			});
		},
		//编辑信息
		handleedit(){
			this.$router.push({
				path: "/mine"
			});
		},
		//注册
		handlezhuce(type){
			if(type == 'zhuce'){
				this.istype = 'add'
				this.zhuceshow  = true
			}else{
				this.istype = 'password'
				this.getpasswordshow = true
			}
		},
		//打开注册认证
		handlezhuceiForm(type){
			if(this.istype == 'add'){
				if(type=="open"){
					this.zhuceshow = true
				}else{
					this.zhuceshow = false
				}
			}else{
				if(type=="open"){
					this.getpasswordshow = true
				}else{
					this.getpasswordshow = false
				}
			}
		},
		//监听滚动
		handleScroll() {
		    var scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
			// console.log(scrolltop)
			if(scrolltop > 500){
				this.showmenuBlack = true
				this.newifwhite = false
				this.newisstyle = "background: #FFF;"
			}else{
				this.showmenuBlack = false
				if(this.ifwhite){
					this.newifwhite = true
					this.newisstyle = ""
				}
			}
		},
		//退出
		outLogin(){
			var _this = this
			this.$confirm("确认要退出登录嘛？", '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$http.post("app/hlexam/login/logout", {}).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.$message.success('退出登录成功！')
						localStorage.setItem('token','');
						_this.SET_ISLOGIN(false)
						_this.SET_USERINFO({})
						//保存
						localStorage.setItem('key_state','')
						_this.$util.routerPath('/')
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}).catch(() => {});
		},
		//分类查询
		handcommand1(type){
			
		},
		//字体大小
		handcommand2(fontsize){
			if(fontsize=="small"){
				this.fontsize = "小"
			}else if(fontsize=="middle"){
				this.fontsize = "中"
			}else{
				this.fontsize = "大"
			}
			//基数是16，
			// $(".fontsize14").css("font-size",`${fontsize}px`)
			// $("html").setAttribute("data-size","middle")
			window.document.documentElement.setAttribute("data-size",fontsize)
		},
		menuClick(index, url) {
			console.log('我点击了menu');
			if(url){
				this.showMenu = false
				this.current_index = index; // 设置点击后的下标
				this.$emit("menuClick",url)
				if(url=="mine"){
					
				}else if(url=="kefu"){
					//客服
					this.dialogimgVisible = true
				}else{
					this.$router.push({
						name: url
					});
				}
			}else{
				// this.$message.info("此模块暂未开放")
				console.log("无跳转路径")
			}
		},
		//二级跳转
		menuSecHandle(index, url,needindex){
			//二级跳转关闭
			// if(this.isActive&&this.isActive>-1){
			// 	this.isActive = -1;
			// }
			// this.current_index = index; // 设置点击后的下标
			if(url){
				this.showMenu = false
				if(url.indexOf("http://")>-1 || url.indexOf("https://")>-1){
					this.$util.windowOpen(url)
				}else if(url.indexOf("ani_")>-1){
					//跳转指定位置
					this.$emit('scrollToElement',needindex)
				}else{
					const newUrl = url.split('?')[0]
					const params = this.parseString(url)
					this.$router.push({
						path: "/"+newUrl,
						query:params
					});
				}
			}else{
				// this.$message.info("此模块暂未开放")
				console.log("无跳转路径")
			}
		},
		//处理参数
		parseString(url){
		    const obj = {};
		    if (url&&url.split('?').length>1) {
		      const params = url.split('?')[1].split('&');
		      params.map(item => obj[item.split("=")[0]] = item.split("=")[1]);
		    }
		    console.log(obj)
			return obj
		},
		mouseEnter(index) {
			//console.log(index)
			this.isActive = index;
		},
		mouseLeave(index) {
			//console.log(index)
			this.isActive = -1;
		},
		//跳转搜索
		handleSearche(url){
			if(!this.isLogin){
				this.$router.push({
					path: "/login"
				});
				return;
			}
			if(url){
				if(url.indexOf("http://")>-1 || url.indexOf("https://")>-1){
					this.$util.windowOpen(url)
				}else{
					const newUrl = url.split('?')[0]
					const params = this.parseString(url)
					this.$router.push({
						path: "/"+newUrl,
						query:params
					});
				}
			}else{
				console.log("无跳转路径")
				this.$message.info("此模块暂未开放")
			}
		},
	}
};
</script>
<style></style>
