import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//为了方便测试，此处用vuex做全局数据
const store = new Vuex.Store({
	state: {
		bsnName:"蕉质 Benein | 舒适感科技",
		userInfo: {},
		isLogin: false,//是否登录
		remark: '',
		curpage:"home",
		sysRole:"heli",
		cepingobj:{
			time:"",
			code:""
		},//测试对象
		difficultyList:['入门','基础','普及','提高','省级'],
	},
	mutations: {
		SET_CURPAGE(state, curpage) {
			console.log(curpage)
			state.curpage = curpage
		},
		SET_cepingobj(state, cepingobj) {
			state.cepingobj = cepingobj
		},
		SET_REMARK(state, remark) {
			state.remark = remark
		},
		SET_USERINFO(state, userInfo) {
			// console.log(userInfo)
			state.userInfo = userInfo
		},
		SET_ISLOGIN(state, isLogin) {
			console.log(isLogin)
			state.isLogin = isLogin
		}
	}
})

export default store
